import {showNotification} from '@ReduxActions'
import {DEFAULT_PAGE_SIZE, DEFAULT_SORT, GetNBAsUseCaseParams, getNBAsUseCase} from '@UseCases'
import {keepPreviousData, useQuery, useQueryClient} from '@tanstack/react-query'
import {PaginationState, SortingState} from '@tanstack/react-table'
import {
  ComponentPropsWithRef,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {DataTable} from '../../shared-components/DataTable'
import {store} from '../../store'
import {getTableColumns} from './components/columns'
import {Icon} from 'pepsico-ds'
// import {setContent} from '@/store/slices/nbaListSlice'

const initialRequestParams = {
  pageNumber: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_SORT,
  query: '',
}

type NBAsListTableProps = ComponentPropsWithRef<'div'>

export type NBAsListTableHandler = {
  reload: () => void
}

export const NBAsListTable = forwardRef<NBAsListTableHandler, NBAsListTableProps>((_, ref: Ref<unknown>) => {
  const {formatMessage} = useIntl()

  // const dispatch = useAppDispatch()
  // const nbaList = useAppSelector(state => state.nbaList)

  const [searchParams, setSearchParams] = useSearchParams()
  const pageInSearchParams = searchParams.get('page')
  const sortInSearchParams = searchParams.get('sort')
  const sizeInSearchParams = searchParams.get('pageSize')
  const queryInSearchParams = searchParams.get('query')

  const queryClient = useQueryClient()
  const {requestParams, setRequestParams} = useRequestParams(
    pageInSearchParams,
    sizeInSearchParams,
    sortInSearchParams,
    queryInSearchParams
  )
  const onPaginationDidChange = useOnPaginationDidChange(setRequestParams, setSearchParams)
  const onSortingDidChange = useOnSortingDidChange(requestParams, setRequestParams, setSearchParams)
  const sortingColumn = useSortingColumn(requestParams.sort)
  const onSearchQueryChange = useOnSearchQueryChange(requestParams, setRequestParams, setSearchParams)
  console.log(requestParams)

  const {isFetching, data, error} = useQuery({
    queryKey: ['nbas', requestParams],
    queryFn: ({signal}) => getNBAsUseCase(requestParams, signal),
    placeholderData: keepPreviousData,
  })

  // useEffect(() => {
  //   dispatch(setContent(data))
  // }, [data, dispatch])

  useEffect(() => {
    if (error) {
      console.error('NBAsListTable::useEffect::error', error)
      onLoadNBAsError(error.message)
    }
  }, [error])

  useImperativeHandle(ref, () => ({
    reload: () => {
      if (pageInSearchParams || sizeInSearchParams) {
        onPaginationDidChange({
          pageIndex: pageInSearchParams ? initialRequestParams.pageNumber : requestParams.pageNumber,
          pageSize: sizeInSearchParams ? initialRequestParams.pageSize : requestParams.pageSize,
        })
      }

      if (sortInSearchParams) {
        const [id, desc] = initialRequestParams.sort.split(',')
        onSortingDidChange([{id, desc: desc === 'desc'}])
      }

      if (queryInSearchParams) {
        onSearchQueryChange(queryInSearchParams)
      }

      queryClient.invalidateQueries({queryKey: ['nbas', requestParams]})
    },
  }))

  return (
    <>
      {isFetching && (
        <p className="text-md my-16 items-center text-center" data-testid="nbas-loading">
          {formatMessage({id: 'NBA_LIST.LOADING_TABLE_LABEL'})}
        </p>
      )}
      {!isFetching && error && (
        <p className="text-md my-16 items-center text-center" data-testid="nbas-error">
          {formatMessage({id: 'NBA_LIST.ERROR_TABLE_LABEL'})}
        </p>
      )}
      {!isFetching && !error && data?.content?.length == 0 && (
        <div className="rounded-sm border border-primary-100">
          <div className=" mb-4 mt-8 flex justify-center [&_.icon]:!text-4xl	">
            <Icon icon="mobiledata_off" size="medium" className="$color-icon" />
          </div>
          <p className="text-md mb-4  items-center text-center font-semibold" data-testid="no-results">
            {formatMessage({id: 'NBA_LIST.NODATA_TITLE'})}
          </p>
          <p className="mb-16 items-center text-center text-xs">{formatMessage({id: 'NBA_LIST.NODATA_SUBTITLE'})}</p>
        </div>
      )}
      {!isFetching && !error && (data?.content?.length ?? 0) > 0 && (
        <DataTable
          columns={getTableColumns(formatMessage)} //, setIsNBADetailsOpen, setSelectedNBAId
          data={data?.content}
          pagination={{
            pageIndex: requestParams.pageNumber,
            pageSize: requestParams.pageSize,
          }}
          pageCount={data?.pagination?.totalPages ?? 0}
          onPaginationDidChange={onPaginationDidChange}
          sortingColumn={sortingColumn}
          onSortingDidChange={onSortingDidChange}
          // onSearchQueryChange={onSearchQueryChange} TODO: don not remove
          data-testid="nbas-content"
          data-cy="nbas-content"
        />
      )}
    </>
  )
})
export default NBAsListTable

// MARK: - Custom hooks

const useRequestParams = (
  pageInSearchParams: string | null,
  sizeInSearchParams: string | null,
  sortInSearchParams: string | null,
  queryInSearchParams: string | null
) => {
  const [requestParams, setRequestParams] = useState<Required<GetNBAsUseCaseParams>>({
    ...initialRequestParams,
    pageNumber: Math.max(Math.max(0, Number(pageInSearchParams) - 1), initialRequestParams.pageNumber),
    pageSize: Number(sizeInSearchParams) || initialRequestParams.pageSize,
    sort: sortInSearchParams?.length ? sortInSearchParams : initialRequestParams.sort,
    query: queryInSearchParams || initialRequestParams.query,
  })

  return {requestParams, setRequestParams}
}

const useOnPaginationDidChange = (
  setRequestParams: React.Dispatch<React.SetStateAction<Required<GetNBAsUseCaseParams>>>,
  setSearchParams: ReturnType<typeof useSearchParams>[1]
) => {
  return useCallback(
    ({pageIndex, pageSize}: PaginationState) => {
      setRequestParams(prev => ({...prev, pageNumber: pageIndex, pageSize: pageSize}))
      setSearchParams(oldParams => {
        oldParams.set('page', `${pageIndex + 1}`)
        return oldParams
      })
    },
    [setRequestParams, setSearchParams]
  )
}

const useOnSortingDidChange = (
  requestParams: Required<GetNBAsUseCaseParams>,
  setRequestParams: React.Dispatch<React.SetStateAction<Required<GetNBAsUseCaseParams>>>,
  setSearchParams: ReturnType<typeof useSearchParams>[1]
) => {
  return useCallback(
    (sorting: SortingState) => {
      const column = sorting[0]
      const sortIndicator = column?.desc ? 'desc' : 'asc'
      const newSort = !column ? '' : `${column.id},${sortIndicator}`

      setRequestParams({
        ...requestParams,
        sort: newSort,
      })
      setSearchParams(oldParams => {
        oldParams.set('sort', newSort)
        return oldParams
      })
    },
    [requestParams, setRequestParams, setSearchParams]
  )
}

const useSortingColumn = (sort: string): SortingState => {
  return useMemo(() => {
    const [id, desc] = sort.split(',')
    return id ? [{id, desc: desc === 'desc'}] : []
  }, [sort])
}

const useOnSearchQueryChange = (
  requestParams: Required<GetNBAsUseCaseParams>,
  setRequestParams: React.Dispatch<React.SetStateAction<Required<GetNBAsUseCaseParams>>>,
  setSearchParams: ReturnType<typeof useSearchParams>[1]
) => {
  return useCallback(
    (query: string) => {
      setRequestParams({
        ...requestParams,
        query,
      })
      setSearchParams(oldParams => {
        oldParams.set('query', query)
        return oldParams
      })
    },
    [requestParams, setRequestParams, setSearchParams]
  )
}

const onLoadNBAsError = (message: string) => {
  store.dispatch(
    showNotification({
      show: true,
      type: 'error',
      title: 'Something went wrong!',
      message: message ?? 'Please try again later.',
    })
  )
}
